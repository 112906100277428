import { useEffect } from "react";
import { statusTranslations } from "../helpers/translations";

import redcard from "../images/icons/red-card.png";

function MatchCard(props) {
    const data = props.data;

    if (!data) return;
    if (data.blockType != "match") return;

    const classname = () => {
        if (["42", "2", "12", "13", "6", "7", "38", "46", "10", "11", "43"].includes(data.status)) return "match match--live";
        return "match";
    }   

    const status = () => {

        if (data.status == 1) {
            const timestamp = data.time;
            let date = new Date(timestamp * 1000);
            let hours = date.getHours();
            let minutes = "0" + date.getMinutes();
            let formattedTime = hours + ':' + minutes.substr(-2);
            return formattedTime;
        }
        return statusTranslations[data.status];
    }

    const score = () => {
        if (data.status == 1 || data.status == 4){
            return (
            <>
            <div>-</div>
            <div>-</div>
            </>
            );
        }
        return (
        <>
            <div>{data.commandA_score}</div>
            <div>{data.commandB_score}</div>
        </>);
    }

    const redCard = (command) => {
        if (command == "a" && data.commandA_redCard > 0) return (<img src={redcard} alt="" className="match-event__icon" />)
        if (command == "b" && data.commandB_redCard > 0) return (<img src={redcard} alt="" className="match-event__icon" />)
    }
    redCard();

    return(
        <a class={classname()} href={"/match/" + props.data.id}>
            <div class="match__left">
                <div class="match__status">{status()}</div>
                <div class="match__players">
                    <div class="match-player">
                        <img class="match-player__icon"
                            src={data.commandA_icon} />
                        <span>{data.commandA_name}</span>
                        {redCard("a")}
                    </div>
                    <div class="match-player">
                        <img class="match-player__icon"
                            src={data.commandB_icon} />
                        <span>{data.commandB_name}</span>
                        {redCard("b")}
                    </div>
                </div>
            </div>
            <div class="match__score">
                {score()}
            </div>
        </a>
    );
}

export default MatchCard;